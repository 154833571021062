import pathLength from './pathLength'
import subPath from './subPath'

// Removes child paths from PATHS
const removeChildren = paths => {
  const map = {}

  paths.forEach(path => {
    map[path] = true
  })

  paths.forEach(path => {
    for (let l = 0; l <= pathLength(path) - 1; l += 1) {
      if (map[subPath(path, l)]) {
        delete map[path]
        break
      }
    }
  })

  return paths.filter(path => map[path])
}

export default removeChildren
