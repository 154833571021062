// Explore arbitrary object
// eslint-disable-next-line consistent-return
const traverseObject = (obj, callback) => {
  // Array
  if (Array.isArray(obj)) {
    return obj.map(child => traverseObject(child, callback))
  }

  // Non-object
  if (!obj || typeof obj !== 'object') {
    return obj
  }

  for (const child of Object.values(obj)) {
    traverseObject(child, callback)
  }

  callback(obj)
}

export default traverseObject
