import getObject from './getObject'
import getParentPath from './getParentPath'

const getSiblings = (list, path) => {
  const parentPath = getParentPath(path)

  if (parentPath === null) {
    return list
  }

  const parentObj = getObject(list, parentPath)
  return (parentObj && parentObj.children) || []
}

export default getSiblings
