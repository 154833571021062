import getObject from './getObject'
import pathLength from './pathLength'
import nextPath from './nextPath'
import subPath from './subPath'

// Gets the optimal insert path for an object
// Takes:
//   objects - array of objects
//   basePath - expicitly specified path
//   prefix - path of parent - usually screen or current selection
const getInsertPath = (objects, basePath, prefix = '', nest = true) => {
  // basePath is invalid
  if (basePath && prefix && subPath(basePath, pathLength(prefix)) !== prefix) {
    basePath = prefix
  }

  // basePath is not specified
  basePath = basePath || prefix

  const target = getObject(objects, basePath)

  if (!target) {
    return `${objects.length}`
  }

  if (target.children && nest) {
    return `${basePath}.${target.children.length}`
  }

  return nextPath(basePath)
}

export default getInsertPath
