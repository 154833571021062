import getParentPath from './getParentPath'
import getSiblings from './getSiblings'

export interface EditorObject {
  id: string
  children?: EditorObject[]
}

// this one does not copy the map, it mutates it
const internalRemapSiblings = (
  map: Map<string, string>,
  path: string,
  siblings: EditorObject[]
) => {
  for (let position = 0; position < siblings.length; position += 1) {
    const objPath = path ? `${path}.${position}` : `${position}`
    const obj = siblings[position]
    map.set(obj.id, objPath)

    if (obj.children) {
      internalRemapSiblings(map, objPath, obj.children)
    }
  }
}

const newRemapSiblings = (
  list: EditorObject[],
  path = '0'
): Map<string, string> => {
  const siblings = getSiblings(list, path)
  const parentPath = getParentPath(path)
  const newMap = new Map<string, string>()
  internalRemapSiblings(newMap, parentPath, siblings)
  return newMap
}

export default newRemapSiblings
