import { sortPaths } from '../sorting'
import pathLength from './pathLength'
import subPath from './subPath'

const getGroupPath = paths => {
  if (paths.length === 0) {
    return null
  }

  let commonPath = paths[0]

  paths.forEach(path => {
    for (let l = pathLength(commonPath); l >= 0; l -= 1) {
      if (subPath(path, l) === subPath(commonPath, l)) {
        commonPath = subPath(commonPath, l)
        break
      }
    }
  })

  paths = sortPaths(paths)

  return paths[0]
    .split('.')
    .slice(0, commonPath.length + 1)
    .join('.')
}

export default getGroupPath
