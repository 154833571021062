const subPath = (path, length) => {
  if (!path || length === 0) return ''
  let index
  for (let i = 0; i < length; i += 1) {
    index = path.indexOf('.', index + 1)
    if (index === -1) return path
  }
  return path.substring(0, index)
}

export default subPath
