const sort = (arr, keyFunc = itm => itm) => {
  return arr.slice(0).sort((a, b) => {
    const aKey = keyFunc(a)
    const bKey = keyFunc(b)

    if (aKey < bKey) {
      return -1
    }
    if (bKey < aKey) {
      return 1
    }
    return 0
  })
}

export default sort
