const deepEqual = require('deep-equal')

const identity = itm => itm

const uniqueElements = (arr, getter = identity, deep = false) => {
  const set = new Set()
  const result = []

  arr.forEach(element => {
    const val = getter(element)

    if (deep) {
      let exists = false

      for (let i = 0; i < result.length; i += 1) {
        if (deepEqual(getter(result[i]), val)) {
          exists = true
          break
        }
      }

      if (!exists) {
        result.push(element)
      }
    } else if (!set.has(val)) {
      result.push(element)
      set.add(val)
    }
  })

  return result
}

export default uniqueElements
