const deepGet = (obj, key = []) => {
  if (key.length === 0) {
    return obj
  }

  if (!obj) {
    return undefined
  }

  return deepGet(obj[key[0]], key.slice(1))
}

export default deepGet
