import {
  PLAN_FREE,
  PLAN_ERA_NONE,
  CURRENT_ERA,
  PAST_ERAS,
  PLAN_ERAS,
} from '@adalo/constants'
import type { PlanType } from '@adalo/constants'

export const getPlanEra = (planType: PlanType) => {
  if (!planType) {
    return PLAN_ERA_NONE
  }

  for (const eraId in PLAN_ERAS) {
    if (PLAN_ERAS[eraId].includes(planType)) {
      return eraId
    }
  }

  // Make sure new plan types are added to PLAN_ERAS
  throw new Error(`Unknown planType: ${planType}`)
}

export const isLegacyPlanType = (planType: PlanType) =>
  PAST_ERAS.includes(getPlanEra(planType))

export const isCurrentPlanType = (planType: PlanType) =>
  getPlanEra(planType) === CURRENT_ERA

export const normalizePlanType = (subscriptionPlanType: PlanType) => {
  if (!subscriptionPlanType) {
    return PLAN_FREE
  }

  return subscriptionPlanType
}

export const isPaidPlan = (planType: PlanType) =>
  normalizePlanType(planType) !== PLAN_FREE
