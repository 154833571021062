import { sortPaths } from '../sorting'
import pathLength from './pathLength'
import subPath from './subPath'

// eslint-disable-next-line consistent-return
const getCommonParent = paths => {
  const minDepth = Math.min(...paths.map(p => pathLength(p)))

  for (let depth = minDepth - 1; depth >= 0; depth -= 1) {
    const firstVal = subPath(paths[0], depth)
    let error = false

    for (const path of paths) {
      if (subPath(path, depth) !== firstVal) {
        error = true
        break
      }
    }

    if (!error) {
      const pathsAtDepth = sortPaths(paths.map(p => subPath(p, depth + 1)))

      return [
        firstVal || null,
        pathsAtDepth[0],
        pathsAtDepth[pathsAtDepth.length - 1],
      ]
    }
  }
}

export default getCommonParent
