const calculateSnapPoints = (elements, params, excludeIds = []) => {
  const map = {}

  Object.keys(elements).forEach(id => {
    if (excludeIds.indexOf(id) !== -1) {
      return
    }

    const el = elements[id]
    const bbox = el.getBoundingClientRect()

    const points = params.map(param => Math.round(bbox[param]))

    points.forEach(pt => {
      if (!map[pt]) {
        map[pt] = []
      }

      map[pt].push(id)
    })
  })

  const arr = Object.keys(map).map(i => +i)

  arr.sort((a, b) => a - b)

  const result = arr.map(point => ({
    point,
    objects: map[point],
  }))

  return result
}

export default calculateSnapPoints
