const getObject = (objects, path) => {
  if (!objects || !path || path.length === 0) {
    return null
  }

  if (typeof path === 'string') {
    path = path.split('.')
  }

  let currentObject = objects[path[0]]
  for (let i = 1; i < path.length; i += 1) {
    if (!currentObject) {
      return null
    }
    currentObject = currentObject.children[path[i]]
  }
  return currentObject
}

export default getObject
