const buildIndex = (objects, keyGetter, multi = false) => {
  const index = {}

  for (let i = 0; i < objects.length; i += 1) {
    const obj = objects[i]
    const key = keyGetter(obj)

    if (multi) {
      if (!index[key]) {
        index[key] = []
      }
      index[key].push(obj)
    } else {
      index[key] = obj
    }
  }

  return index
}

export default buildIndex
