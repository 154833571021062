import getObject from './getObject'
import pathLength from './pathLength'
import shallowEqual from './shallowEqual'
import subPath from './subPath'
import removeInfinity from './removeInfinite'
import update from './update'

const updateBounds = (list, map, path, shouldUpdate = null) => {
  const obj = getObject(list, path)

  if (!obj) {
    return list
  }

  if (shouldUpdate && !shouldUpdate(obj)) {
    return list
  }

  let minX = Infinity
  let minY = Infinity
  let maxX = -Infinity
  let maxY = -Infinity

  obj.children.forEach(child => {
    if (child.x < minX) {
      minX = child.x
    }
    if (child.x + child.width > maxX) {
      maxX = child.x + child.width
    }

    if (child.y < minY) {
      minY = child.y
    }
    if (child.y + child.height > maxY) {
      maxY = child.y + child.height
    }
  })

  const x = removeInfinity(minX, 0)
  const y = removeInfinity(minY, 0)
  const width = removeInfinity(maxX - minX, 0)
  const height = removeInfinity(maxY - minY, 0)

  const updatedObj = {
    ...obj,
    x,
    y,
    width,
    height,
  }

  if (!shallowEqual(obj, updatedObj, ['x', 'y', 'width', 'height'])) {
    list = update(list, path, updatedObj)
    const parentPath = subPath(path, pathLength(path) - 1)
    list = updateBounds(list, map, parentPath, shouldUpdate)
  }

  return list
}

export default updateBounds
