import { comparePaths } from '../sorting'
import isSibling from './isSibling'
import pathLength from './pathLength'
import subPath from './subPath'

const getDropPath = (dropPath, itemPaths) => {
  const length = pathLength(dropPath)
  const pieces = dropPath.split('.')

  const pathsByLength = {}

  itemPaths.forEach(path => {
    const length = pathLength(path)

    if (!pathsByLength[length]) {
      pathsByLength[length] = []
    }

    pathsByLength[length].push(path)
  })

  for (let i = 0; i < length; i += 1) {
    const l = i + 1
    const paths = pathsByLength[l] || []

    const subDropPath = subPath(dropPath, l)

    paths.forEach(path => {
      if (
        isSibling(subDropPath, path) &&
        comparePaths(path, subDropPath) === -1
      ) {
        // Do something
        pieces[i] -= 1
      }
    })
  }

  return pieces.join('.')
}

export default getDropPath
