const deepFilter = (objects, func) => {
  const result = []

  for (const obj of objects) {
    if (!func(obj)) {
      // eslint-disable-next-line no-continue
      continue
    }

    let newObj = obj

    if (obj.children) {
      newObj = {
        ...obj,
        children: deepFilter(obj.children, func),
      }
    }

    result.push(newObj)
  }

  return result
}

export default deepFilter
