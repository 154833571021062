const transformPath = (points, transform) => {
  if (!transform) {
    return points
  }

  return points.map(({ point, inControl, outControl }) => ({
    point: transform(point),
    inControl: inControl && transform(inControl),
    outControl: outControl && transform(outControl),
  }))
}

export default transformPath
