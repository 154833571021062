// Deep equivalent of { ...obj1, ...obj2 }
const deepMerge = (obj1, obj2) => {
  if (obj1 !== undefined && obj2 === undefined) {
    return obj1
  }

  if (obj2 !== undefined && !obj1 === undefined) {
    return obj2
  }

  if (
    !obj1 ||
    typeof obj1 !== 'object' ||
    Array.isArray(obj1) ||
    !obj2 ||
    typeof obj2 !== 'object' ||
    Array.isArray(obj2)
  ) {
    return obj2
  }

  const result = {}

  const obj2Keys = new Set(Object.keys(obj2))

  for (const key of Object.keys({ ...obj1, ...obj2 })) {
    if (!obj2[key] && obj2Keys.has(key)) {
      result[key] = obj2[key]

      if (typeof result[key] === 'undefined') {
        delete result[key]
      }

      // eslint-disable-next-line no-continue
      continue
    }

    result[key] = deepMerge(obj1[key], obj2[key])
  }

  return result
}

export default deepMerge
