const evaluate = (newObject, object) => {
  const diff = {}

  Object.keys(newObject).forEach(key => {
    if (typeof newObject[key] === 'function') {
      diff[key] = newObject[key](object)
    } else {
      diff[key] = newObject[key]
    }
  })

  return diff
}

export default evaluate
