const translateChildren = (group, oldGroup) => {
  if (!group.children || !group.children.length) {
    return group
  }

  const diffX = group.x - oldGroup.x
  const diffY = group.y - oldGroup.y

  if (!diffX && !diffY) {
    return group
  }

  const translated = { ...group }

  translated.children = translated.children.map(child =>
    translateChildren(
      {
        ...child,
        x: child.x + diffX,
        y: child.y + diffY,
      },
      child
    )
  )

  return translated
}

export default translateChildren
