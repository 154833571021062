const sameElements = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false
  }

  arr1 = arr1.slice(0)
  arr2 = arr2.slice(0)

  arr1.sort((a, b) => a - b)
  arr2.sort((a, b) => a - b)

  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }

  return true
}

export default sameElements
