import joinPaths from './joinPaths'
import pathLength from './pathLength'
import subPath from './subPath'

const decrementPath = (path, amount = 1) => {
  const length = pathLength(path)
  const start = subPath(path, length - 1)
  const pieces = path.split('.')
  let last = pieces[pieces.length - 1]
  last = `${Math.max(0, +last - +amount)}`

  return joinPaths(start, last)
}

export default decrementPath
