import getPoint from './getPoint'

const getSegment = (points, i) => {
  const startPoint = getPoint(points, i)
  const endPoint = getPoint(points, i + 1)
  const startControl = startPoint.outControl || startPoint.point
  const endControl = endPoint.inControl || endPoint.point

  const data = [startControl, endControl, endPoint.point]
  return ` C ${data.map(d => d.join(' ')).join(', ')}`
}

export default getSegment
