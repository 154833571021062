const { StatusCodes } = require('http-status-codes')
const jwt = require('jsonwebtoken')
const util = require('util')

const verifyJWT = util.promisify(jwt.verify)
const prepareKey = key => {
  return key.replace(/\\n/g, '\n').replace(/\\/g, '')
}
const getTokenPayload = (token, key) => verifyJWT(token, key)

// Used in stripe-server and TODO: needs to be used in database eventually
const verifyAppUser = publicKey => {
  return async (req, res, next) => {
    const match = `${req.headers.authorization}`.match(/^Bearer (.+)$/)

    try {
      const [, token] = match
      const payload = await getTokenPayload(token, prepareKey(publicKey))
      const { userId } = payload

      req.userId = userId

      return next()
    } catch (err) {
      // fall-through
    }

    return res.sendStatus(StatusCodes.BAD_REQUEST)
  }
}

export default verifyAppUser
