import parseSVG from 'svg-path-parser'
import equal from 'deep-equal'

const parseSVGPath = svgPath => {
  const path = parseSVG(svgPath || '')
  let points = []
  let isClosed = false

  if (path.length === 0) {
    return { points, isClosed }
  }

  for (let i = 0; i < path.length; i += 1) {
    const piece = path[i]
    const { command, x, y, x1, y1, x2, y2 } = piece

    // eslint-disable-next-line default-case
    switch (command) {
      case 'moveto':
      case 'lineto':
        points.push({ point: [x, y] })
        break

      case 'curveto':
        // Add outControl to prev point
        if (points.length > 0) {
          points[points.length - 1].outControl = [x1, y1]
        }

        // Push new point with inControl
        points.push({
          point: [x, y],
          inControl: [x2, y2],
        })

        break

      case 'closepath':
        if (
          points.length > 1 &&
          equal(points[0].point, points[points.length - 1].point)
        ) {
          points[0].inControl = points[points.length - 1].inControl
          points = points.slice(0, points.length - 1)
        }

        isClosed = true

        break
    }
  }

  return { points, isClosed }
}

export default parseSVGPath
