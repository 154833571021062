const char = '.'
const pathLength = path => {
  if (!path) {
    return 0
  }
  let count = 0
  for (const c of path) {
    if (c === char) count += 1
  }
  return count + 1
}

export default pathLength
