import traverse from './traverse'

const getObjectsOfType = (objects, type) => {
  const results = []

  traverse(objects, obj => {
    if (obj.type === type) {
      results.push(obj.id)
    }
  })

  return results
}

export default getObjectsOfType
