const shallowEqual = (obj1, obj2, fields) => {
  if (!obj1 || !obj2 || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2
  }

  if (!fields) {
    fields = Object.keys({ ...obj1, ...obj2 })
  }

  for (let i = 0; i < fields.length; i += 1) {
    if (obj1[fields[i]] !== obj2[fields[i]]) {
      return false
    }
  }

  return true
}

export default shallowEqual
