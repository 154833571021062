import Bezier from 'bezier-js'

import { flatten } from '../arrays'

const getPathBbox = (points, isClosed) => {
  const length = isClosed ? points.length : points.length - 1

  let minX = Infinity
  let minY = Infinity
  let maxX = -Infinity
  let maxY = -Infinity

  if (points.length === 1 && length === 0) {
    const [x, y] = points[0].point
    return { x, y, width: 0, height: 0 }
  }

  for (let i = 0; i < length; i += 1) {
    const start = points[i]
    const end = points[(i + 1) % points.length]

    const data = flatten([
      start.point,
      start.outControl || start.point,
      end.inControl || end.point,
      end.point,
    ])

    const segment = new Bezier(data)
    const bbox = segment.bbox()

    if (bbox.x.min < minX) {
      minX = bbox.x.min
    }
    if (bbox.y.min < minY) {
      minY = bbox.y.min
    }

    if (bbox.x.max > maxX) {
      maxX = bbox.x.max
    }
    if (bbox.y.max > maxY) {
      maxY = bbox.y.max
    }
  }

  if (
    Number.isNaN(minX) ||
    Number.isNaN(minY) ||
    Number.isNaN(maxX) ||
    Number.isNaN(maxY)
  ) {
    return { x: 0, y: 0, width: 0, height: 0 }
  }

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  }
}

export default getPathBbox
