// Single-level array-flattening function
const flatten = arr => {
  let result = []

  for (const itm of arr) {
    result = result.concat(itm)
  }

  return result
}

export default flatten
