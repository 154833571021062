const comparePaths = (a, b) => {
  const aPieces = a.split('.')
  const bPieces = b.split('.')

  const maxCount = Math.max(aPieces.length, bPieces.length)

  for (let i = 0; i < maxCount; i += 1) {
    if (+aPieces[i] > +bPieces[i] || bPieces[i] === undefined) {
      return 1
    }

    if (+bPieces[i] > +aPieces[i] || aPieces[i] === undefined) {
      return -1
    }
  }

  return 0
}

export default comparePaths
