export const TOLERENCE = 3

const getSnap = (snapPoints, point) => {
  // Refactor to use in-place array tree operation
  // Find point before and after

  let previousPoint

  for (let i = 0; i < snapPoints.length; i += 1) {
    if (snapPoints[i].point === point) {
      return snapPoints[i]
    }
    if (snapPoints[i].point > point) {
      const posDiff = snapPoints[i].point - point

      if (posDiff > TOLERENCE) {
        return previousPoint
      }

      if (!previousPoint) {
        return snapPoints[i]
      }

      const negDiff = point - previousPoint.point

      const posScore = snapPoints[i].objects.length / posDiff
      const negScore = previousPoint.objects.length / negDiff
      return posScore > negScore ? snapPoints[i] : previousPoint
    }
    if (point - snapPoints[i].point <= TOLERENCE) {
      previousPoint = snapPoints[i]
    }
  }

  return previousPoint
}

export default getSnap
