interface Object {
  x: number
  y: number
  width: number
  height: number
}

const getBoundingBox = (objects: Object[]) => {
  let x1 = Infinity
  let x2 = -Infinity
  let y1 = Infinity
  let y2 = -Infinity

  if (objects.length === 0) {
    return null
  }

  objects.forEach(obj => {
    if (obj.x < x1) {
      x1 = obj.x
    }

    if (obj.x + obj.width > x2) {
      x2 = obj.x + obj.width
    }

    if (obj.y < y1) {
      y1 = obj.y
    }

    if (obj.y + obj.height > y2) {
      y2 = obj.y + obj.height
    }
  })

  let width = x2 - x1
  let height = y2 - y1

  if (x1 === Infinity) {
    x1 = 0
  }
  if (y1 === Infinity) {
    y1 = 0
  }
  if (width === -Infinity) {
    width = 0
  }
  if (height === -Infinity) {
    height = 0
  }

  return {
    x: x1,
    y: y1,
    width,
    height,
  }
}

export default getBoundingBox
