import moment from 'moment'

const relativeDate = dateString => {
  if (!dateString) {
    return null
  }

  const dateObj = new Date(dateString)
  return moment(dateObj).fromNow()
}

export default relativeDate
