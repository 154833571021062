import getParentPath from './getParentPath'
import getSiblings from './getSiblings'

// this one does not copy the map, it mutates it
const internalRemapSiblings = (map, path, siblings) => {
  for (let position = 0; position < siblings.length; position += 1) {
    const objPath = path ? `${path}.${position}` : `${position}`
    const obj = siblings[position]
    map[obj.id] = objPath

    if (obj.children) {
      internalRemapSiblings(map, objPath, obj.children)
    }
  }
}

const remapSiblings = (list, map, path = '0') => {
  const newMap = map ? { ...map } : {}
  const siblings = getSiblings(list, path)
  const parentPath = getParentPath(path)
  internalRemapSiblings(newMap, parentPath, siblings)
  return newMap
}

export default remapSiblings
