import { sortPaths } from '../sorting'
import subPath from './subPath'

// Gets screen paths of each object in PATHS
const getComponentPaths = paths => {
  const componentPaths = paths.map(p => subPath(p, 1))

  return sortPaths(Array.from(new Set(componentPaths)))
}

export default getComponentPaths
