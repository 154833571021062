import traverse from './traverse'

const deepFind = (objects, func) => {
  const result = []

  traverse(objects, obj => {
    if (func(obj)) {
      result.push(obj)
    }
  })

  return result
}

export default deepFind
