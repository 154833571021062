import comparePaths from './comparePaths'

const sortPaths = paths => {
  paths = [...paths]

  paths.sort((a, b) => comparePaths(a, b))

  return paths
}

export default sortPaths
