const anyField = (obj, fields, func) => {
  for (let i = 0; i < fields.length; i += 1) {
    const field = fields[i]

    if (func(obj[field])) {
      return true
    }
  }

  return false
}

export default anyField
