import { DeviceBreakpoint, DeviceType } from '@adalo/constants'

const isMobile = (width: number) => {
  return width <= DeviceBreakpoint.MOBILE_BREAKPOINT
}

const isTablet = (width: number) => {
  return (
    width > DeviceBreakpoint.MOBILE_BREAKPOINT &&
    width <= DeviceBreakpoint.TABLET_BREAKPOINT
  )
}

export const getDeviceType = (width: number) => {
  // Handle case where width < 0
  width = Math.abs(width)

  if (isMobile(width)) {
    return DeviceType.MOBILE
  }
  if (isTablet(width)) {
    return DeviceType.TABLET
  }

  return DeviceType.DESKTOP
}
