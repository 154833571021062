import pathLength from './pathLength'
import subPath from './subPath'

const isSibling = (path1, path2) => {
  const length = pathLength(path1)

  if (length !== pathLength(path2)) {
    return false
  }

  return subPath(path1, length - 1) === subPath(path2, length - 1)
}

export default isSibling
