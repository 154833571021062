// Array.indexOf but with an accessor func
// Useful because of immutability
const indexOf = (arr, accessor) => {
  for (let i = 0; i < arr.length; i += 1) {
    if (accessor(arr[i])) {
      return i
    }
  }

  return -1
}

export default indexOf
