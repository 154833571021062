// Explore & map arbitrary object
const traverseMapObject = (obj, callback) => {
  // Array
  if (Array.isArray(obj)) {
    return obj.map(child => traverseMapObject(child, callback))
  }

  // Non-object
  if (!obj || typeof obj !== 'object') {
    return obj
  }

  const newObj = {}

  for (const key of Object.keys(obj)) {
    const child = obj[key]
    newObj[key] = traverseMapObject(child, callback)
  }

  const result = callback(newObj)

  return result
}

export default traverseMapObject
