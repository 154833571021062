import joinPaths from './joinPaths'
import pathLength from './pathLength'
import subPath from './subPath'

const incrementPath = (path, amount = 1) => {
  const length = pathLength(path)
  const start = subPath(path, length - 1)
  const pieces = path.split('.')
  let last = pieces[pieces.length - 1]
  last = `${+last + +amount}`

  return joinPaths(start, last)
}

export default incrementPath
