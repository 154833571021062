import pathLength from './pathLength'
import subPath from './subPath'

const isChildPath = (parentPath, childPath) => {
  if (pathLength(childPath) <= pathLength(parentPath)) {
    return false
  }

  return subPath(childPath, pathLength(parentPath)) === parentPath
}

export default isChildPath
