import { getBoundingBox } from '../geometry'
import deepMap from './deepMap'

const resizeObjects = (objects, newBbox, resizeChildren = () => true) => {
  if (newBbox.width < 0 || newBbox.height < 0) {
    return objects
  }

  const bbox = getBoundingBox(objects)

  let newObjects = objects

  if (bbox.width === 0) {
    newObjects = deepMap(newObjects, obj => ({
      ...obj,
      x: newBbox.x,
      width: newBbox.width,
    }))
  } else {
    newObjects = deepMap(newObjects, obj => {
      const relativeX = (obj.x - bbox.x) / bbox.width

      return {
        ...obj,
        x: newBbox.x + newBbox.width * relativeX,
        width: (obj.width * newBbox.width) / bbox.width,
      }
    })
  }

  if (bbox.height === 0) {
    newObjects = deepMap(newObjects, obj => ({
      ...obj,
      y: newBbox.y,
      height: newBbox.height,
    }))
  } else {
    newObjects = deepMap(newObjects, obj => {
      const relativeY = (obj.y - bbox.y) / bbox.height

      return {
        ...obj,
        y: newBbox.y + newBbox.height * relativeY,
        height: (obj.height * newBbox.height) / bbox.height,
      }
    })
  }

  return newObjects
}

export default resizeObjects
