import StableMatch from '@protonapp/stablematch'
import levenshtein from 'fast-levenshtein'

import { sort } from '../arrays'

const clean = str => {
  return str.toLowerCase()
}

// Implements stable marriage problem to fuzzy-match two lists of labels
// Preferences are symmetrical, and therefore matches will be optimal

// LISTA & LISTB are arrays of strings

const matchLists = (listA, listB) => {
  const result = {}

  const getAPreferences = a => {
    a = clean(a)
    return sort(listB, b => levenshtein.get(a, clean(b)))
  }

  const getBPreferences = b => {
    b = clean(b)
    return sort(listA, a => levenshtein.get(a, clean(b)))
  }

  const matches = StableMatch.match(
    listA,
    listB,
    getAPreferences,
    getBPreferences
  )

  for (const match of matches) {
    // eslint-disable-next-line prefer-destructuring
    result[match[0]] = match[1]
  }

  return result
}

export default matchLists
