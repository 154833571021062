import subPath from './subPath'
import pathLength from './pathLength'
import updateBounds from './updateBounds'

const updateParentBounds = (list, map, id, path, shouldUpdate) => {
  path = path || map[id]

  if (!path) {
    return list
  }

  const parentPath = subPath(path, pathLength(path) - 1)
  return updateBounds(list, map, parentPath, shouldUpdate)
}

export default updateParentBounds
