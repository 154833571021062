// Depth-first tree-traversal
const traverse = (objects, func, traverseChildren, parentObj) => {
  for (let i = 0; i < objects.length; i += 1) {
    const obj = objects[i]

    func(obj, parentObj, objects[i - 1], objects[i + 1])

    if (obj && obj.children && (!traverseChildren || traverseChildren(obj))) {
      if (!Array.isArray(obj.children)) {
        throw new Error(`obj.children is not an Array: ${JSON.stringify(obj)}`)
      }

      traverse(obj.children, func, traverseChildren, obj)
    }
  }
}

export default traverse
