// Depth-first tree map
const deepMap = (objects, func, mapChildren) => {
  const result = []

  for (const obj of objects) {
    let newObj = obj
    const originalChildren = obj ? obj.children : undefined

    if (newObj && newObj.children && (!mapChildren || mapChildren(newObj))) {
      newObj = {
        ...newObj,
        children: deepMap(newObj.children, func),
      }
    }

    newObj = func(newObj, originalChildren)

    result.push(newObj)
  }

  return result
}

export default deepMap
