// eslint-disable-next-line @typescript-eslint/default-param-last
const deepSet = (obj, key = [], value) => {
  if (key.length === 0) {
    return value
  }

  if (!obj) {
    obj = {}
  }

  return {
    ...obj,
    [key[0]]: deepSet(obj[key[0]], key.slice(1), value),
  }
}

export default deepSet
