const buildUpdate = (path, obj, insert = false) => {
  const removeCount = insert ? 0 : 1

  if (!path) {
    path = []
  }
  if (typeof path === 'string') {
    path = path.split('.')
  }

  if (path.length === 0) {
    return {}
  }

  if (path.length === 1) {
    const spliceObj = [path[0], removeCount]

    // Only add this if not undefined / null
    if (obj) {
      spliceObj.push(obj)
    }

    return {
      $splice: [spliceObj],
    }
  }

  return {
    [path[0]]: {
      children: buildUpdate(path.slice(1), obj, insert),
    },
  }
}

export default buildUpdate
