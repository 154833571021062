import getSegment from './getSegment'
import transformPath from './transformPath'

// POINTS has the following format:
//  [
//    {
//      point: [x, y],
//      inControl: [x, y] || null,
//      outControl: [x, y] || null
//    }
//  ]
const createPath = (points, isClosed, transform) => {
  if (points.length === 0) {
    return ''
  }

  points = transformPath(points, transform)

  const firstPoint = points[0]
  let commands = [`M ${firstPoint.point.join(' ')}`]

  commands = commands.concat(
    points.slice(0, points.length - 1).map((pt, i) => getSegment(points, i))
  )

  if (isClosed) {
    commands.push(getSegment(points, points.length - 1))
    commands.push(' Z')
  }

  return commands.join('')
}

export default createPath
