const mergeReducers = (initialState, ...reducers) => {
  let middlewares = []

  if (Array.isArray(reducers[0])) {
    // eslint-disable-next-line prefer-destructuring
    middlewares = reducers[0]
    reducers = reducers.slice(1)
  }

  // eslint-disable-next-line @typescript-eslint/default-param-last
  return (state = initialState, action) => {
    const prevState = state

    for (const reducer of reducers) {
      const result = reducer(state, action)

      if (result !== undefined && result !== state) {
        state = result
      }
    }

    for (const middleware of middlewares) {
      const result = middleware(state, prevState)

      if (result !== undefined) {
        state = result
      }
    }

    return state
  }
}

export default mergeReducers
