import immutabilityHelper from 'immutability-helper'

import buildUpdate from './buildUpdate'

const insert = (objects, newPath, ...newObjects) => {
  newObjects = newObjects.slice().reverse()

  newObjects.forEach(newObject => {
    objects = immutabilityHelper(objects, buildUpdate(newPath, newObject, true))
  })

  return objects
}

export default insert
