// Returns object with highest score returned from FUNC
// Runs in O(N) because does not attempt to sort entire list
const optimize = (objects, func) => {
  let best = null
  let bestScore = -Infinity

  for (let i = 0; i < objects.length; i += 1) {
    const obj = objects[i]
    const score = func(obj)

    if (score > bestScore) {
      best = obj
      bestScore = score
    }
  }

  return best
}

export default optimize
