import shortUUID from 'short-uuid'
import uuid from 'uuid/v4'

const BASE_36_ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyz'

const leftPad = (str, length, character) => {
  for (let i = str.length; i < length; i += 1) {
    str = `${character}${str}`
  }

  return str
}

const getId = () => {
  const translater = shortUUID(BASE_36_ALPHABET)

  const id = translater.fromUUID(uuid())

  return leftPad(id, 25, '0')
}

export default getId
