const xor = (arr1, arr2) => {
  const newArr = arr1.slice(0)

  arr2.forEach(itm => {
    const index = newArr.indexOf(itm)

    if (index === -1) {
      newArr.push(itm)
    } else {
      newArr.splice(index, 1)
    }
  })

  return newArr
}

export default xor
