import anyField from './anyField'

const getObjectsInRect = (objects, rect) => {
  if (!rect) {
    return []
  }

  const { x, y, width, height } = rect

  const results = []

  objects.forEach(obj => {
    if (
      anyField(obj, ['x', 'y', 'width', 'height'], f => typeof f !== 'number')
    ) {
      return
    }

    if (
      !(obj.x + obj.width < x || x + width < obj.x) &&
      !(obj.y + obj.height < y || y + height < obj.y)
    ) {
      results.push(obj.id)
    }
  })

  return results
}

export default getObjectsInRect
